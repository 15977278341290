<template>
    <v-layout fill-height>
        <v-col wrap justify-center>
            <v-flex md12>
                <material-card title="Edit Product Info"
                               text="Show and edit product information">
                    <w-org-edit-product :auth_type="2" :id="id"></w-org-edit-product>
                </material-card>
            </v-flex>
        </v-col>
    </v-layout>
</template>
<style lang="scss">

</style>

<script>

    export default {
        components: {},
        data() {
            return {
                id: 0,
            }
        },
        created() {
            this.id = parseInt(this.$route.params.id);
        },
        methods: {

        }
    }
</script>
